import i18n from "@/i18n";
import { markRaw, toRaw } from "vue";
import {
  Plus,
  Delete,
  BottomLeft,
  TopRight,
  Search,
  Download,
  Edit,
} from "@element-plus/icons-vue";
import api from "@/api";
import moment from "moment";
import { getUploadUrl } from "../../utils/tools";
const { t } = i18n.global;
export default {
  tableCols: [
    { name: t("drivers.dCompanyID"), filed: "cName" },
    { name: t("drivers.dName"), filed: "dName" },
    { name: t("drivers.dSex"), filed: "dSex" },
    { name: t("drivers.dContactTel"), filed: "dContactTel" },
    { name: t("drivers.dLastUpdated"), filed: "dLastUpdated" },
  ],
  tableOps: [
    {
      opType: "btn",
      icon: markRaw(Edit),
      name: t("commKey.Edit"),
      type: "",
      click: (row, callback) => {
        api.sys_drivers_row({ id: row.id }).then((res) => {
          // console.log(res.data.row, "11");
          let _row = res.data.row;
          _row.carids = _row.carids.split(",");
          callback({ type: "edit", row: _row });
        });
      },
    },
    {
      opType: "del",
      icon: markRaw(Delete),
      name: t("commKey.Delete"),
      type: "danger",
      click: (row, callback) => {
        callback({ type: "delete", row: row });
        // activeKey.value = "test";
      },
    },
  ],
  searchCols: [
    {
      type: "ipt",
      title: t("commKey.keynameDriver"),
      key: "keyname",
      val: "",
    },

    {
      type: "btn",
      btnType: "success",
      icon: markRaw(Search),
      title: t("commKey.Search"),
      click: (callback) => {
        callback({ type: "search" });
      },
    },
    {
      type: "btn",
      btnType: "primary",
      title: t("commKey.Add"),
      icon: markRaw(Plus),
      click: (callback) => {
        callback({ type: "add", row: { id: 0 } });
      },
    },
  ],
  formCols: [
    {
      type: "sel",
      label: t("drivers.dCompanyID"),
      field: "dCompanyID",
      placeholder: t("drivers.dCompanyID"),
      val: "",
      data: [],
      required: true,
      change: (val, item, callback) => {
        api.sys_get_vehicles_all({ company_id: val }).then((res) => {
          callback({ type: "bindcar", data: res.data.data, field: "bindCars" });
        });
      },
    },
    {
      type: "ipt",
      label: t("drivers.dName"),
      field: "dName",
      placeholder: t("drivers.dName"),
      val: "",
      required: true,
    },
    {
      type: "sel",
      label: t("drivers.dSex"),
      field: "dSex",
      placeholder: t("drivers.dSex"),
      val: "",
      data: [],
      required: true,
    },
    {
      type: "ipt",
      label: t("drivers.dContactTel"),
      field: "dContactTel",
      placeholder: t("drivers.dContactTel"),
      val: "",
      required: true,
    },
    // {
    //   type: "ipt",
    //   label: t("drivers.dBodyNo"),
    //   field: "dBodyNo",
    //   placeholder: t("drivers.dBodyNo"),
    //   val: "",
    //   required: false,
    // },
    // {
    //   type: "ipt",
    //   label: t("drivers.dDriverType"),
    //   field: "rCompanyID",
    //   placeholder: t("drivers.dDriverType"),
    //   val: "",
    //   required: false,
    // },
    {
      type: "ipt",
      label: t("drivers.dDriverNo"),
      field: "dDriverNo",
      placeholder: t("drivers.dDriverNo"),
      val: "",
      required: false,
    },
    {
      type: "date",
      label: t("drivers.dDriverStopDate"),
      field: "dDriverStopDate",
      placeholder: t("drivers.dDriverStopDate"),
      val: moment().format("YYYY-MM-DD"),
      required: false,
    },
    {
      type: "msel",
      label: t("drivers.bindCars"),
      field: "carids",
      placeholder: t("drivers.bindCars"),
      val: [],
      data: [],
      required: true,
    },

    // {
    //   type: "ipt",
    //   label: t("drivers.carids"),
    //   field: "rCompanyID",
    //   placeholder: t("drivers.carids"),
    //   val: "",
    //   required: false,
    // },
    {
      type: "date",
      label: t("drivers.dDrvEntrytime"),
      field: "dDrvEntrytime",
      placeholder: t("drivers.dDrvEntrytime"),
      val: moment().format("YYYY-MM-DD"),
      required: false,
    },
    // {
    //   type: "ipt",
    //   label: t("drivers.dDrvICNo"),
    //   field: "rCompanyID",
    //   placeholder: t("drivers.dDrvICNo"),
    //   val: "",
    //   required: false,
    // },
    {
      type: "ipt",
      label: t("drivers.dfinger_img"),
      field: "dfinger_img",
      placeholder: t("drivers.dfinger_img"),
      val: "",
      required: false,
    },
    {
      type: "upload",
      label: t("drivers.dimg"),
      field: "dimg",
      placeholder: t("drivers.dimg"),
      val: "",
      required: false,
    },
  ],
};
